export function deleteElementByIndex(arr, index) {
    if (index < 0 || index >= arr.length) {
      // Check if the index is out of bounds
      return arr;
    }
  
    // Use the `splice` method to remove the element at the specified index
    arr.splice(index, 1);
  
    return arr;
  }
  