import React from 'react'
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Buttons_1} from './Buttons';
import {Buttons_2} from './Buttons';
import {deleteElementByIndex} from "./helpers"
import BuildingsTab from './BuildingsTab';
import './test.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };

export default function Test() {
const [mainTapValue, setMainTapValue] = React.useState('1');
const handleChangeMainTaps = (event, newValue) => {
    setMainTapValue(newValue);
  };



  /*I must handel this to work with full page with customer needs and requirment
  firs step make it run in front end with all needs >> still here
  second, make it work correctly
  third, build a backend
  forth, style
  fifth, test then deploy to server 
  */

  const projectState = ["بدا الحجز","بدا البيع","قريبا","مباع"];
  const [state, setState] = React.useState("مباع")
  const stateOnChange = (e) =>{
    setState(e.target.value)
  }
  // main info
  const [projectNameAr, setProjectNameAr] = React.useState("");
  const [projectNameEn, setProjectNameEn] = React.useState("");
  const [projectLocation, setProjectLocation] = React.useState("");
  const handelProjectNameAr = (e) => {
    setProjectNameAr(e.target.value)
  }

  // types
  const [buildingNumber, setBuildingNumber] = React.useState(1);
  const [buildings, setBuildings] = React.useState([]);

   // Function to check if all text fields in previous buildings are not empty
   const arePreviousBuildingsValid = () => {
    return buildings.every((building) => {
      return (
        building.name.trim() !== "" &&
        building.image.trim() !== ""
      );
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    console.log("ALL DATA SAVED CORRECTLY")
  } 

  return (
    <div>
        <Box className="mainBoxINFOAlwaqil Test" sx={{ ...style, width: '80%' }}>
          <h4
            style={{
              width: '40%',
              textAlign: 'center',
              margin: 'auto',
              marginTop: '3%'
            }}
          >
            معلومات المشروع
          </h4>
          <div className="addBookingTab">
            <TabContext className="addBookingTab" value={mainTapValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  // onChange={handleChangeMainTaps}
                  aria-label="lab API tabs example"
                  // disabled
                >
                  <Tab label= "المعلومات الاساسية" value="1" />
                  <Tab label= "المباني" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <>
                  <form
                    Validate
                    autoComplete="off"
                    onSubmit={(e) => handleChangeMainTaps(e, '2')}
                  >
                  <div className="tabsContent bookingData">
                    <TextField
                      id="standard-basic"
                      label="اسم المشروع بالعربي"
                      required
                      value={projectNameAr}
                      onChange={(e) => setProjectNameAr(e.target.value)}
                    ></TextField>

                    <TextField
                      id="standard-basic"
                      label="اسم المشروع بالإنجليزي"
                      required
                      value={projectNameEn}
                      onChange={(e) => setProjectNameEn(e.target.value)}
                    ></TextField>

                    <TextField
                      className="appartmentNumber"
                      id="standard-select"
                      label= "حالة المشروع"
                      value={state}
                      select
                      required
                      onChange={(e) => stateOnChange(e)}
                    >
                      {projectState.map((e,key) => (
                        <MenuItem key={key} value={e}>
                        {e}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="standard-select"
                      className="appartmentNumber"
                      label="موقع المشروع"
                      value={projectLocation}
                      onChange={(e) => setProjectLocation(e.target.value) }
                    >
                    </TextField>

                  </div>
                  <Buttons_1 handleChangeMainTaps={handleChangeMainTaps}/>
                  </form>
                </>
              </TabPanel>
              <TabPanel value="2">
                <>
               
                  <div className="tabsContent">
                    <TextField
                        id="standard-basic"
                        label="عدد المباني"
                        className="sizeField"
                        type='number'
                        disabled
                        // value={projectNameAr}
                        InputProps={{
                          inputProps: { 
                              min: 1 
                          }
                        }}
                        value={buildings.length}
                    />

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      {Array.isArray(buildings) && (
                        <BuildingsTab
                          buildings={buildings}
                          setBuildings={setBuildings}
                          setBuildingNumber={setBuildingNumber}
                          projectNameAr={projectNameAr}
                          deleteElementByIndex={deleteElementByIndex}
                          handelProjectNameAr={handelProjectNameAr}
                        />
                      )}
                    </Box>
                  </div>
                  
                  <Buttons_2 handleChangeMainTaps={handleChangeMainTaps}/>
                  
                </>
              </TabPanel>
            </TabContext>
          </div>
        </Box> 
    </div>
  )
}
