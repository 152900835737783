import React from 'react';
import './home.css';
import Form from 'react-bootstrap/Form';

import BoxTask from './BoxTask';
import Time from '../time/Time';
import logo from '../images/1.png';
import { getUserAttendance } from '../../api';
import AttendanceReport from './AttendanceReport';
import Delayed from './Delayed';
import { getUsersData} from '../../api';

export default function Home(props) {
  const [allProjects, setAllProjects] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(props.userData.name);
  // code after update v3.1
  const [usersData, setUsersData] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState();
  const [allusersNames_Emails, setAllusersNames_Emails] = React.useState();
  const [delayTable , setDelayTable] = React.useState(false);


  const getStream = async (e) => {
    const allUserAttendanceData = await getUserAttendance(e);
    const res = Promise.resolve(allUserAttendanceData);
    return res;
  };

  const [userAttendance, setUserAttendance] = React.useState({});

  React.useEffect(async () => {
    let tempProjects = props.project;
    props.project.forEach((e, key) => {
      if (e.location === '') {
        tempProjects[key]['location'] =
          'https://www.google.com/maps/place/%D9%85%D8%B4%D8%B1%D8%B9+%D8%B4%D8%B1%D9%83%D8%A9+%D8%A7%D9%88%D8%AA%D8%A7%D8%B1+%D8%A7%D9%84%D8%B9%D8%A7%D9%84%D9%85%D9%8A%D9%87%E2%80%AD%E2%80%AD/@21.5170266,39.1989864,18z';
      }
    });
    setAllProjects(tempProjects);
    props.handelHeader(false);
    // must handel background **
    // nothing yet

    const allUserAttendanceData = getStream(props.userEmail);
    await allUserAttendanceData.then(async (e) => {
      // setData(e);
      setUserAttendance(e.data);
      // console.log(e.data);
    });
  }, [userAttendance == {}]);

  React.useEffect(async () => {
    let users = {};
    let tempUserData = [];
    await getUsersData(props.userData.isOwner).then((e) => {
      setUsersData(e);
      tempUserData = e;
    });
    tempUserData.map((e, key) => {
      users[e.name] = e.email;
    });
    setAllusersNames_Emails(users);
    setAllUsers(Object.keys(users));
  }, []);


  /* new code */

  const [selectedList, setSelectedList] = React.useState('الرئيسية');

  // handel clicked list for showing the list content in main page
  const handelClickedList = (e) => {
    setSelectedList(e.target.innerText);
  };

  const [showing_At_Report, set_At_Report] = React.useState(false);
  const handelShowingReport = () => {
    set_At_Report(true);
  };

  const closedAllReports = () => {
    set_At_Report(false);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  // delay(10000);

  const onChangeUser = async (e) => {
    setSelectedUser(e);
    const allUserAttendanceData = getStream(allusersNames_Emails[e]);
    await allUserAttendanceData.then(async (e) => {
      // setData(e);
      setUserAttendance(e.data);
      // console.log(e.data);
    });
  };


  let today = new Date();
    let date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();

  return (
    <div className="attendanceMainDiv">
      <div className="parentDiv">
        <div className="div_1">
          <div className="topOfAt">
            <div className="grid-item-At">
              <Time />
            </div>
            <div className="grid-item-At">
              <div className="profileDivAt">
                <img
                  className="profileImageAt"
                  src={`https://www.w3schools.com/howto/img_avatar${props.userData.gender === "انثى" ? "2" : ""}.png`}
                  alt="Avatar"
                  width={120}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <div className="profileDivAt">{props.userData.name}</div>
            </div>
          </div>
          <Delayed waitBeforeShow={5000} isDealy={delayTable}>
            {!showing_At_Report ? (
              <BoxTask
                currentList={selectedList}
                userEmail={props.userEmail}
                allProjects={allProjects}
                userAttendance={userAttendance}
                handelShowingReport={(e) => handelShowingReport}
              />
            ) : (
              // select employee to display attendance
              <>
                <Form.Control
                  className="employeeList"
                  as="select"
                  disabled={props.userData.isOwner || props.userData.isAdmin || props.userData.isHR ? false : true}
                  onChange={async(e) => {
                    console.log(e.target.value)
                    onChangeUser(e.target.value);
                    setDelayTable(true)
                    await delay(2000);
                    setDelayTable(false)
                  }}
                  value={selectedUser}
                >
                  <option value={props.userData.name} key={-1}>
                    {props.userData.name}
                  </option>
                  {allUsers.map((e, index) => (
                    <option key={index}>{e}</option>
                  ))}
                </Form.Control>
                {!delayTable ? <AttendanceReport userAttendance={userAttendance} /> : null}
              </>
            )}
          </Delayed>
        </div>
        <div className="div_2">
          <div className="logoDivAt">
            <img src={logo} width={120} style={{ cursor: 'pointer' }} />
          </div>
          <div className="rightSide">
            <p
              className={`${selectedList == 'الرئيسية' ? 'chosenList' : ''}`}
              onClick={(e) => {
                handelClickedList(e);
                closedAllReports();
              }}
            >
              الرئيسية
            </p>
            <p
              className={`${selectedList == 'التقارير' ? 'chosenList' : ''}`}
              onClick={(e) => {
                handelClickedList(e);
                closedAllReports();
              }}
            >
              التقارير
            </p>
            <p
              className={`${selectedList == 'الطلبات' ? 'chosenList' : ''}`}
              onClick={(e) => {
                handelClickedList(e);
                closedAllReports();
              }}
            >
              الطلبات
            </p>
               
                {/* show edit list for admin and owner */}
              
                {props.userData.isAdmin ? 
                <>
                  <p
              className={`${selectedList == 'إدارة' ? 'chosenList' : ''}`}
              onClick={(e) => {
                handelClickedList(e);
                closedAllReports();
              }}
            >
              إدارة
            </p>
                </> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}