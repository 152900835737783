import React from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@material-ui/core';
import FloorTab from './FloorTab';
import "./buildingsTab.css"

const BuildingsTab = ({ buildings, setBuildings, setBuildingNumber, projectNameAr, deleteElementByIndex, handelProjectNameAr }) => {
    const [tempBuildings, setTempBuildings] = React.useState([])
    const [buildingsTapValue, setBuildingsTapValue] = React.useState("0");
    const [missing, setMissing] = React.useState();
    const handleChangeBuildingsTaps = (event, newValue) => {
        setBuildingsTapValue(newValue);
    };
    const [floor, setFloor] = React.useState(0);  
    const arePreviousBuildingsValid = () => {
        return buildings.every((building, index) => {
            setMissing(`${index}`);
          return (
            building.name.trim() !== "" &&
            building.image.trim() !== ""
          );
        });
      };

    React.useEffect(async () => {
        setTempBuildings(buildings)
        if (buildings){
            console.log("buildings: ", buildings)
        }
      }, [buildings]);
      const handelBuildingInfo = (e, type, index) => {
        const updatedBuildings = [...tempBuildings]; // Create a copy of tempBuildings
        const currentBuilding = updatedBuildings[index];  
        if (currentBuilding) {
            if (type === "name") {
                currentBuilding.name = e.target.value;
                // if (!currentBuilding.name)
                // currentBuilding.name = "لايوجد اسم"
            } else if (type === "img") {
                currentBuilding.image = e.target.value;
                // if (!currentBuilding.image)
                // currentBuilding.image = "https://w1.pngwing.com/pngs/638/872/png-transparent-real-estate-facade-building-architecture-commercial-building-building-design-architectural-design-competition-architectural-engineering.png"
            } else if (type === "apartments") {
                if(e.target.value)
                currentBuilding.total = e.target.value;
            } else if (type === "floors") {
                if(e.target.value){
                    currentBuilding.floors = e.target.value;
                }
            }
            updatedBuildings[index] = currentBuilding;
            setTempBuildings(updatedBuildings); // Update the state with the modified buildings
            console.log("temp building" , tempBuildings)
            setBuildings(tempBuildings)
        } else {
            // Handle the case where currentBuilding is undefined
            console.error(`currentBuilding is undefined at index ${index}`);
        }
    }

    const handelSubmit = (e) => {
        e.preventDefault();
        if (arePreviousBuildingsValid()) {
            console.log("----------- Adding Building -----------")
            console.log(buildingsTapValue)
            let currentBuildings = buildings;
            let buildingInfo = {
            name: `مبنى ${buildings.length + 1}`,
            image: "https://w1.pngwing.com/pngs/638/872/png-transparent-real-estate-facade-building-architecture-commercial-building-building-design-architectural-design-competition-architectural-engineering.png",
            total: 32,
            floors: [],
            };
            console.log(buildingInfo)
            currentBuildings.push(buildingInfo);
            setBuildings(currentBuildings);
            // setTempBuildings(currentBuildings)
            const newIndex =
            buildings.length === 0 ? 0 : buildings.length - 1;
            setBuildingsTapValue(`${newIndex}`);
            setBuildingNumber(buildings.length + 1);
            console.log("-----------------------------------")
        } else {
            setBuildingsTapValue(missing)
            // alert("يجب ادخال جميع البيانات التي تحتوي على علامة *");
        }
    }
    return (
    <TabContext className="addBookingTab" value={buildingsTapValue}>
        <>
            <div className='TestDive'>
                <TabList onChange={handleChangeBuildingsTaps} aria-label="lab API tabs example">
                {
                    buildings.length > 0 ? (
                    buildings.map((e,i) => (
                        <Tab key={i} label={`مبنى ${i + 1}`} value={`${i}`} />
                    ))) : null  
                }
                </TabList>

                <button 
                    className="btn btn-primary"
                    onClick={(e) => handelSubmit(e)}
                >
                    إضافة مبنى
                </button>
            </div>
            {buildings.length > 0 ? (
                buildings.map((e, j) => (
                    <TabPanel value={`${j}`} key={j}>
                            <div className="tabsContent bookingData">
                                <TextField
                                    id="standard-basic"
                                    label="اسم المبنى"
                                    value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                    onChange={(e) => handelBuildingInfo(e, "name",j)}
                                    required
                                />
                                <TextField
                                    id="standard-basic"
                                    label="عدد الوحدات"
                                    value={tempBuildings[j] ? tempBuildings[j].total : ""}
                                    onChange={(e) => handelBuildingInfo(e, "apartments",j)}
                                    type='number'
                                    required
                                    InputProps={{
                                        inputProps: { 
                                            min: 1 
                                        }
                                    }}
                                />
                                <TextField
                                    id="standard-basic"
                                    label="صورة المبنى"
                                    value={tempBuildings[j] ? tempBuildings[j].image : ""}
                                    onChange={(e) => handelBuildingInfo(e, "img",j)}
                                    required
                                />
                                <img src={`${tempBuildings[j] ? tempBuildings[j].image : ""}`} alt={`Building number ${j+1} Image`} width="100" height="110">
                                </img>
                                <TextField
                                    id="standard-basic"
                                    label="عدد الادوار"
                                    value={tempBuildings[j] ? tempBuildings[j].floors.length : 1}
                                    type='number'
                                    required
                                    disabled
                                    InputProps={{
                                        inputProps: { 
                                            min: 0 
                                        }
                                    }}
                                />

                                
                                {Array.isArray(buildings[j].floors) && (
                                    <FloorTab
                                    buildings={buildings}
                                    index={j}
                                    setBuildings={setBuildings}
                                    buildingsTapValue={buildingsTapValue}
                                    deleteElementByIndex={deleteElementByIndex}
                                    setBuildingNumber={setBuildingNumber}
                                    
                                    />
                                )}
                               
                            </div>
                    </TabPanel>
                ))
            ) : (
                null
            )}
      

          </>
            {
            buildings.length > 0 ? 
                <button
                    className="btn btn-primary"
                        onClick={(e) => {
                            console.log("----------- Deleting Building -----------")
                            console.log(buildingsTapValue)
                            console.log(`تم حذف المبنى بنجاح \nاسم المشروع: ${projectNameAr}\nمعلومات المبنى المحذوف:\n${JSON.stringify(buildings[buildingsTapValue])}`)
                            deleteElementByIndex(buildings, buildingsTapValue)
                            const newIndex = buildingsTapValue > 0 ? buildingsTapValue - 1 : 0;
                            setBuildingsTapValue(`${newIndex}`);
                            setBuildingNumber(buildings.length)
                            console.log("-----------------------------------")
                        }}
                >
                    حذف المبنى
                </button> : null
            }
    </TabContext>
  );
};

export default BuildingsTab;
