import React from 'react';

const Buttons_1 = ({handleChangeMainTaps}) => {
  return (
    <div className="buttons">
      <button
        className="btn btn-primary next"
        onClick={(e) => {
          console.log("NEXT")
        }}
        type="submit"
      >
        التالي
      </button>
      <button
        className="btn btn-primary cancel"
        onClick={(e) => {
         console.log("CANCEL")
        }}
        type='button'
      >
        إلغاء
      </button>
    </div>
  );
};

const Buttons_2 = ({handleChangeMainTaps}) => {
  return (
    <div className="buttons">
      <button
        className="btn btn-primary save"
        onClick={(e) => {
          console.log("SAVE")
        }}
      >
        حفظ
      </button>
      <button
        className="btn btn-primary past"
        onClick={(e) => {
         console.log("BACK")
         handleChangeMainTaps(e, "1")
        }}
      >
        رجوع
      </button>
    </div>
  );
};

export  {Buttons_1, Buttons_2};
