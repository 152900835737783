import React from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AppartementTab from './AppartementsTab';
import "./floorTab.css"

const FloorTab = ({buildings, index, setBuildings, buildingsTapValue, deleteElementByIndex, setBuildingNumber }) =>{
    const [tempFloors, setTempFloors] = React.useState([])
    const [floorTapValue, setFloorTapValue] = React.useState("0");
    const [missing, setMissing] = React.useState();
    const handleChangeFloorsTaps = (event, newValue) => {
        setFloorTapValue(newValue);
    };
    const [tempBuildings, setTempBuildings] = React.useState([])

    React.useEffect(async () => {
        setTempBuildings(buildings)
        // console.log(buildings)
    }, [buildings]);
 
    const handelSubmit = (e) =>{
        console.log(e)
    }
    const handelAddingFloor = (e) => {
        e.preventDefault();
        console.log("----------- Adding Floor -----------")
        let tempBuildings = [...buildings];
        tempBuildings[index]["floors"].push({
            appartements: []
        })
        console.log("tempBuildings[index][floors]" , tempBuildings[index]["floors"])
        setBuildings(tempBuildings)
        console.log("-----------------------------------")
    }
    
    const [refresh, setRefresh] = React.useState(1);

    return(
        <TabContext className="addBookingTab" value={floorTapValue}>
            <div className='TestDive'>
            <input type="hidden" value={refresh} />
            <TabList onChange={handleChangeFloorsTaps} aria-label="lab API tabs example">
            {
                tempBuildings.length > 0 && tempBuildings[index]["floors"].length > 0 ? (
                    tempBuildings[index]["floors"].map((e,i) => (
                    <Tab key={i} label={`الدور ${i + 1}`} value={`${i}`} />
                ))) : null  
            }
            </TabList>

            <button 
                className="btn btn-primary"
                type="submit"
                onClick={(e) => handelAddingFloor(e)}
            >
                إضافة دور
            </button>
            </div>
            {tempBuildings.length > 0 && tempBuildings[index]["floors"].length > 0 ? <> {(
                <AppartementTab
                buildings={tempBuildings}
                index={index}
                setBuildings={setBuildings}
                floorTapValue={floorTapValue}
                deleteElementByIndex={deleteElementByIndex}
                />                
            )
                
            }
                
             </>: (
                null
            )}
      

            {
            tempBuildings.length > 0 && tempBuildings[index]["floors"].length > 0 ? 
                <button
                    className="btn btn-primary"
                        onClick={(e) => {
                            console.log("----------- Deleting Floor -----------")
                            console.log("tempBuildings[index][floors]: " , tempBuildings[index]["floors"])
                            deleteElementByIndex(tempBuildings[index]["floors"], floorTapValue)
                            console.log("floorTapValue: " + floorTapValue)
                            const newIndex = floorTapValue > 0 ? floorTapValue - 1 : 0;
                            setFloorTapValue(`${newIndex}`);
                            setBuildingNumber(buildings.length)
                            setBuildings(buildings)
                            setRefresh(refresh + 1)
                            console.log("-----------------------------------")
                        }}
                >
                    حذف الدور
                </button> : null
            }
    </TabContext>
    )
}
export default FloorTab;