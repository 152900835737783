import React from 'react';
import './home.css';

/*this page must render new page contains wlecome message with some shortcut for pages*/
export default function Home() {
  // here i should put pages info (names and url)
  const pages = [
    {
      name:"صفحة التحضير",
      url:"https://awtar.org/attendance"
    },
    {
      name:"صفحة ال QR code",
      url:"https://awtar.org/barcodes"
    }
  ]
  // handel page clicked, click must open destination page
  const handelOnclick = (url) => {
    window.open(url, '_blank');
  }
  // create div contain p with page info, number of dev's independent on pages length const
  const topLandingItems = pages.map((element, key) => (
    <div className='topLandingItems' onClick={(e) => handelOnclick(element.url)}>
      <p>{element.name}</p>
    </div>
  ))
  return (
    <div className="bgimgLanding">
      <div className="topLanding">
       {topLandingItems}
      </div>
      <div className="middleLanding">
        <h1>أهلاً وسهلاً بكم</h1>
        <hr />
        <p>شركة أوتار العقارية</p>
      </div>
      <div className="bottomleftLanding">
        <p>@ِAwtar.org</p>
      </div>
    </div>
  );
}
