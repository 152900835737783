import React from 'react';

import Tasks from './Tasks';
import { TextField } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

/**/
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers'; 
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';


 
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};



export default function BoxTask({
  currentList,
  userEmail,
  allProjects,
  userAttendance,
  handelShowingReport
}) {
  let [leave, setLeave] = React.useState(false);
  let [attend, setAttend] = React.useState(true);
  let [selected, setSelected] = React.useState("")
  const handelAfterAttend = () => {
    setLeave(true);
    setAttend(false);
    console.log('Done');
  };

  // to handel start of day
const defaultDayStartChange = (e) => {
  console.log(e)
}
// to handel end of day
const defaultDayEndChange = (e) => {
  console.log(e)
  let hour = e["$H"]%12
  let min = e["$m"]
  console.log(`${hour}:${min}`)
}
// to handel lates min
const defaultLateChange = (e) => {
  console.log(e["$m"])
}
// handel day works
const defaultDayWorkChange = (e) => {
  setWorkDay(e)
}
const [workDay, setWorkDay] = React.useState(5)


  let newDate = new Date();
  const tasks = {
    الرئيسية: [
      {
        className: 'homeTasks1',
        paragraph: 'التحضير',
        isShowing:
          attend &&
          (Object.keys(userAttendance) !== 0
            ? `${parseInt(
                newDate.getMonth() + 1
              )}-${newDate.getDate()}-${newDate.getFullYear()}` in
              userAttendance
              ? userAttendance[
                  parseInt(newDate.getMonth() + 1) +
                    '-' +
                    newDate.getDate() +
                    '-' +
                    newDate.getFullYear()
                ]['attend']
                ? false
                : true
              : true
            : true)
      },
      {
        className: 'homeTasks2',
        paragraph: 'الإنصراف',
        isShowing:
          leave ||
          (Object.keys(userAttendance) !== 0
            ? `${parseInt(
                newDate.getMonth() + 1
              )}-${newDate.getDate()}-${newDate.getFullYear()}` in
              userAttendance
              ? `attend` in
                userAttendance[
                  parseInt(newDate.getMonth() + 1) +
                    '-' +
                    newDate.getDate() +
                    '-' +
                    newDate.getFullYear()
                ]
                ? 'leave' in
                  userAttendance[
                    parseInt(newDate.getMonth() + 1) +
                      '-' +
                      newDate.getDate() +
                      '-' +
                      newDate.getFullYear()
                  ]
                  ? false
                  : true
                : true
              : false
            : true)
      }
    ],
    التقارير: [
      {
        className: 'reportTask1',
        paragraph: 'تقرير الحضور',
        isShowing: true
      },
      {
        className: 'reportTask2',
        paragraph: 'تقرير الإستئذان',
        isShowing: false
      },
      {
        className: 'reportTask3',
        paragraph: 'تقرير الإجازة',
        isShowing: false
      }
    ],
    الطلبات: [
      {
        className: 'orderTasks1',
        paragraph: 'طلب إستئذان',
        isShowing: false
      },
      {
        className: 'orderTasks2',
        paragraph: 'طلب إجازة',
        isShowing: false
      }
    ],
    إدارة: [
      {
        className: 'manageTasks1',
        paragraph: 'تعديل ساعات عمل الموظف',
        isShowing: true
      },
      {
        className: 'manageTasks2',
        paragraph: 'TEST',
        isShowing: false
      }
    ]
  };


  // handel edit employee hour using Model Box
  const [openEditHour, setOpenEditHour] = React.useState(false);
  const [hiddenEditHour, setHiddenEditHour] = React.useState(false);
  const handleCloseEditHour = () => {
    setOpenEditHour(false);
  };

  const Buttons = () => {
    return (
      <div className="buttons">
        
          <button
            className="btn btn-primary next save"
          >
            حفظ
          </button>
       
        <button
          className="btn btn-primary past cancel"
          onClick={handleCloseEditHour}
        >
          إلغاء
        </button>
      </div>
    );
  };

  const TasksLoader = tasks[currentList].map((e, key) => (
    <Tasks
      task={e}
      key={key}
      userEmail={userEmail}
      allProjects={allProjects}
      userAttendance={userAttendance}
      handelShowingReport={handelShowingReport()}
      handelAfterAttend={handelAfterAttend}
      setSelected={setSelected}
      setOpenEditHour={setOpenEditHour}
    />
  ));


  const [value, setValue] = React.useState(dayjs('2022-10-13T17:50:20'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  return openEditHour === false ? <div className="BoxTaskDiv">{TasksLoader}</div> : 
  <>      
  <Modal
  open={openEditHour}
  onClose={handleCloseEditHour}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
  className="bookingDataEdite"
>
  <Box className="mainBoxINFO" sx={{ ...style, width: "80%" }}>
    <h4
      style={{
        width: "100%",
        textAlign: "center",
        margin: "auto",
        marginTop: "3%",
      }}
      onClick={(e) => setHiddenEditHour(!hiddenEditHour)}
    >
            <LocalizationProvider dateAdapter={AdapterDayjs} className="localizationDiv">
            <DemoContainer
              components={[
                'TimePicker',
                'MobileTimePicker',
                'DesktopTimePicker',
                'StaticTimePicker',
              ]}
            >
      <div className='editHours'>
        <div className='editHoursChildren'>
   
          <label>وقت بداية الدوام (التلقائي)</label>
          
          <DemoItem>
          <TimePicker defaultValue={dayjs('2023-11-09T8:00')} onChange={(e) => defaultDayStartChange(e)}/>
          </DemoItem>

        </div>
        <div className='editHoursChildren'>
        <label>وقت نهاية الدوام (التلقائي)</label>
          <DemoItem>
          <TimePicker defaultValue={dayjs('2023-11-09T4:00')} onChange={(e) => defaultDayEndChange(e)}/>
          </DemoItem>
        </div>
        <div className='editHoursChildren'>
        <label>مدة السماح  (د)</label>
          <DemoItem>
          <TimePicker views={['minutes']} defaultValue={dayjs('2023-11-09T0:15')} format="mm:ss" onChange={(e) => defaultLateChange(e)}/>
          </DemoItem>
        </div>
        <div className='editHoursChildren'>
        <label>عدد ايام العمل</label>
        <input value={workDay} onChange={(e) => defaultDayWorkChange(e.target.value)}></input>
        </div>
        
        
        
      </div>
      </DemoContainer>
      </LocalizationProvider> 
    </h4>

    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {
        /*
              <div className="tabsContent myBookingData">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
          {/* <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          /> 
          <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
          <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
    
        </LocalizationProvider>
      </div>
      <Buttons />
      */}

       
    </Box>
  
  </Box>
</Modal>
</>;
}