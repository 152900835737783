import React from 'react';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import './appartementTab.css'

const AppartementTab = ({buildings, index, floorTapValue, setBuildings, deleteElementByIndex}) => {
    const [appartTabValue, setAppartTabValue] = React.useState("0")
    const handleChangeAppartTaps = (event, newValue) =>{
        setAppartTabValue(newValue)
    } 
    const [t, setT] = React.useState(1)
    const handelAppartementAdd = (e) => {
        e.preventDefault();
        console.log("Appartement added")
        let tempBuildings = [...buildings];
        tempBuildings[index]["floors"][parseInt(floorTapValue)]["appartements"].push(
            {
                "apartment_No": t
            }
        )
        setT(t+1)
        setBuildings(tempBuildings)
        console.log("------------------------------")
    }
    const direction = ["شمالي","شمالي شرقي","شمالي غربي","شرقي","غربي","جنوبي","جنوبي شرقي","جنوبي غربي"]
    const types = ["شقة","فله"]
    const [refresh, setRefresh] = React.useState(1);

    return(
        buildings[index]["floors"].map((e, j) => (
            <TabPanel value={`${j}`} key={j}>
                <div className="tabsContent bookingData appartementTab">
                    <input type="hidden" value={refresh} />
                        <TextField
                            id="standard-basic"
                            label=" عدد الوحدات في الدور"
                            value={buildings[index]["floors"][j]["appartements"].length}
                            type='number'
                            disabled
                            InputProps={{
                                inputProps: { 
                                    min: 1 
                                }
                            }}
                        />
                        <TabContext className="addBookingTab" value={appartTabValue}>
                            <TabList onChange={handleChangeAppartTaps} aria-label="lab API tabs example">
                            {
                                buildings[index]["floors"][j]["appartements"].length > 0 ? (
                                    buildings[index]["floors"][j]["appartements"].map((e,i) => (
                                        <Tab key={i} label={`شقة ${i + 1}`} value={`${i}`} />
                                    ))) : null  
                            }               
                            </TabList>
                            <button
                                className="btn btn-primary"
                                onClick={(e) => handelAppartementAdd(e)}
                            >
                                أضف وحدة
                            </button>
                            {
                            buildings[index]["floors"][j]["appartements"].length > 0 ? (
                                buildings[index]["floors"][j]["appartements"].map((e,i) => (
                                    <TabPanel value={`${i}`} key={i}>
                                        <div className="tabsContent bookingData">
                                          
                                        <TextField
                                            id="standard-basic"
                                            label="رقم الشقة"
                                            value={buildings[index]["floors"][j]["appartements"][appartTabValue] ? buildings[index]["floors"][j]["appartements"][appartTabValue]["apartment_No"] : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                            disabled
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="الرقم التسلسلي"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="المساحة"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                            type='number'
                                            required
                                            InputProps={{
                                            inputProps: { 
                                                min: 100 
                                            }
                                            }}
                                        />
                                        <TextField
                                            id="standard-select"
                                            label="الاتجاه"
                                            select
                                            value={direction[0]}
                                        >
                                            {direction.map((e, key) => {
                                                return (
                                                    <MenuItem key={key} value={e}>
                                                        {e}
                                                    </MenuItem>
                                                );
                                            })}
                                                                                    
                                        </TextField>
                                        <TextField
                                            id="standard-select"
                                            label="النوع"
                                            select
                                            value={types[0]}
                                        >
                                            {types.map((e, key) => {
                                                return (
                                                    <MenuItem key={key} value={e}>
                                                        {e}
                                                    </MenuItem>
                                                );
                                            })}
                                                                                    
                                        </TextField>
                                        <TextField
                                            id="standard-basic"
                                            label="عدد الغرف"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                            type='number'
                                            InputProps={{
                                            inputProps: { 
                                             min: 5 
                                            }
                                            }}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="دورات المياه"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                            type='number'
                                            InputProps={{
                                            inputProps: { 
                                             min: 2 
                                            }
                                            }}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="رقم الصك"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="رقم الصك الجديد"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="رقم العداد"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                        />
                                        <TextField
                                            id="standard-basic"
                                            label="رقم الحساب"
                                            // value={tempBuildings[j] ? tempBuildings[j].name : ""}
                                            // onChange={(e) => handelBuildingInfo(e, "name",j)}
                                        />
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("Appartement Deleted")
                                                console.log(buildings[index]["floors"][parseInt(floorTapValue)]["appartements"])
                                                let tempBuildings = [...buildings]
                                                console.log(i)
                                                console.log(tempBuildings[index]["floors"][parseInt(floorTapValue)]["appartements"][i])
                                                deleteElementByIndex(tempBuildings[index]["floors"][parseInt(floorTapValue)]["appartements"], appartTabValue)
                                                const newIndex = appartTabValue > 0 ? appartTabValue - 1 : 0;
                                                setAppartTabValue(`${newIndex}`);
                                                // setBuildingNumber(buildings.length)
                                                // setBuildings(buildings)
                                                setRefresh(refresh + 1)
                                                console.log("-------------------------------")
                                            }}
                                        >
                                            حذف الشقة
                                        </button>
                                    </TabPanel>
                                ))) : null
                            }
    
    
                        </TabContext>
                </div>
            </TabPanel>
    
        ))
    )


}

export default AppartementTab;