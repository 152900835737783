import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';

/* 
    This Component is use to replace
    Route from react-router-dom
    ---
    It authenticates and redirects based on
    the component passed as props to it.
*/
export default function PrivateRoute({
  component: Component,
  redirectTo: redirectTo,
  privilege,
  ...rest //all other props
}) {
  const { currentUser, currentUserData } = useContext(AuthContext);
  let isAdmin = currentUserData.isAdmin;
  let isMiniAdmin = currentUserData.isMiniAdmin;
  let isSaler = currentUserData.isSaler;
  let isOwner = currentUserData.isOwner;
  let isWorker = currentUserData.isWorker;
  let isAlwaqil = currentUserData.isAlwaqil;

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          rest.path === '/booking' ||
          rest.path === '/mybooking' ||
          rest.path === '/addBooking/:name' ? (
            isOwner || isSaler || isAdmin ? (
              <>
                <Component
                  {...rest}
                  {...props}
                  isAdmin={isAdmin}
                  userEmail={currentUser.email}
                  currentUserData={currentUserData}
                  handelNewBooking={rest.handelNewBooking}
                />
              </>
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/ControlPanel' ||
            rest.path === '/ControlPanel/:id' ? (
            isOwner || isAdmin || isMiniAdmin ? (
              <Component
                {...rest}
                {...props}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/Messages' ? (
            isOwner || isWorker || isAdmin ? (
              <Component
                {...rest}
                {...props}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/الوكيل' ? (
            isOwner || isAdmin || isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                isAdmin={isAdmin}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/manage-users' ? (
            isOwner || isAdmin ? (
              <Component
                {...rest}
                {...props}
                isOwner={isOwner}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/setting' ? (
            isOwner ||
            isAdmin ||
            isSaler ||
            isMiniAdmin ||
            isWorker ||
            isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/newOrder' ? (
            isOwner ||
            isAdmin ||
            isAlwaqil ||
            isSaler ||
            isMiniAdmin ||
            isWorker ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/contract/:name' ? (
            isOwner || isAdmin || isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/attendance' ? (
            isOwner ||
            isAdmin ||
            isSaler ||
            isMiniAdmin ||
            isWorker ||
            isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/REPORTS' ? (
            isOwner || isAdmin || isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/landing' ? (
            isOwner ||
            isAdmin ||
            isSaler ||
            isMiniAdmin ||
            isWorker ||
            isAlwaqil ? (
              <Component
                {...rest}
                {...props}
                userEmail={currentUser.email}
                currentUserData={currentUserData}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : rest.path === '/Test' ? (
            isOwner ||
            isAdmin ||
            isSaler ||
            isMiniAdmin ||
            isWorker ||
            isAlwaqil ? (
              <Component
                {...rest}
                {...props}
              />
            ) : (
              <Redirect to={redirectTo} />
            )
          ) : null
        }
      />
    </>
  );
}
