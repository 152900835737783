import React from 'react';
import { RiSuitcaseLine } from 'react-icons/ri';
import { handelAttendence, handelLeaving } from './Functions';

export default function Tasks({
  task,
  userEmail,
  allProjects,
  userAttendance,
  handelShowingReport,
  handelAfterAttend,
  setSelected,
  setOpenEditHour
}) {
  const handelUserAttendence = (e) => {
    handelAttendence(userEmail, allProjects, handelAfterAttend);
  };
  const handelUserLeaving = (e) => {
    handelLeaving(userEmail, allProjects);
  };
  const handelAttendenceReport = (e) => {
    // handel showing attendance table
    handelShowingReport();
  };
  const handelExcuseReport = (e) => {
    console.log(task.paragraph);
  };
  const handelHolidayReport = (e) => {
    console.log(task.paragraph);
  };
  const handelExcuseRequest = (e) => {
    setSelected(task.paragraph)
    // setOpen(true)
    console.log(task.paragraph);
  };
  const handelHolidayRequest = (e) => {
    console.log(task.paragraph);
  };
  const handelWorkHours = (e) => {
    setSelected(task.paragraph)
    setOpenEditHour(true)
    console.log(task.paragraph)
  }

  const handelClicked = (e) => {
    return task.isShowing
      ? task.paragraph == 'التحضير'
        ? handelUserAttendence(e)
        : task.paragraph == 'الإنصراف'
        ? handelUserLeaving(e)
        : task.paragraph == 'تقرير الحضور'
        ? handelAttendenceReport(e)
        : task.paragraph == 'تقرير الإستئذان'
        ? handelExcuseReport(e)
        : task.paragraph == 'تقرير الإجازة'
        ? handelHolidayReport(e)
        : task.paragraph == 'طلب إستئذان'
        ? handelExcuseRequest(e)
        : task.paragraph == 'طلب إجازة'
        ? handelHolidayRequest(e)
        : task.paragraph == 'تعديل ساعات عمل الموظف' 
        ? handelWorkHours(e)
        : ''
      : '';
  };
  return (
    <div
      className={`TaskMainDiv ${task.className} ${
        task.isShowing ? '' : 'hiddenTask'
      }`}
    >
      <div className="reportIcon">
        <RiSuitcaseLine />
      </div>
      <p onClick={(e) => handelClicked(e)}>{task.paragraph}</p>
    </div>
  );
}