import './App.css';
import './AOS.css';
import React from 'react';

// react components for routing our app without refresh

// core components
import Header from './component/Header/Header.js';
import HeaderLinks from './component/Header/HeaderLinks.js';
import HeaderLinks2 from './component/Header/HeaderLinks2.js';
import Home from './component/Home';
import Projects from './component/Projects';
import Services from './component/Services';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Gallery from './component/Gallery';
import Messages from './component/Messages';
import Login from './component/login/Login';

// Alwaqil
import AlwaqilHomePage from './component/Booking/Alwaqil/HomePage';

//manage users
import ManageUsersHome from './component/manage-users/Home';

import { getData, checkPrivilage } from './api';
import PrivateRoute from './PrivateRoute';
import QRCode from './component/QRCode';
import EachProject from './component/EachProject';

// import files for dashboard
import dashboardHome from './component/DashBoard/Home';
import SingleProject from './component/DashBoard/SingleProject';

import { AuthProvider } from './Auth';

import logo from './component/awtarlogo.png';
import Homepage from './component/Booking/Homepage';
import Mybooking from './component/Booking/mybooking/Mybooking';
import newBooking from './component/Booking/newBooking/NewBooking';

import app from './base';
import AddBooking from './component/Booking/newBooking/AddBooking';
import { useTranslation } from 'react-i18next';
import SettingHome from './component/seeting/Home';
import OrderHome from './component/Booking/Orders/OrderHome';

// Contract
import ContractHome from './component/Contractt/Home';

// Attendance
import HomeAttendance from './component/Attendance/Home';

// Reports
import HomeReports from './component/Reportss/Home';

// Landing
import LandingHome from './component/Landing/Home';

//Test
import Test from './component/Test/Test'

export default function App(props) {
  const [user, setUser] = React.useState('');
  const [currentLang, setCurrentLang] = React.useState(
    (navigator.language || navigator.userLanguage)[0] +
      (navigator.language || navigator.userLanguage)[1]
  );
  const [data, setData] = React.useState([]);

  const [privilege, setPrivilege] = React.useState({
    isAdmin: false,
    isOwner: false,
    isMiniAdmin: false,
    isSaler: false,
    isWorker: false,
    isAlwaqil: false
  });
  const [userData, setUserData] = React.useState({});
  const [toggleDrawer, setToggleDrawer] = React.useState(() => () => {});
  const [refresh, setRefresh] = React.useState(1);

  const [paths] = React.useState([
    'mybooking',
    'booking',
    'الوكيل',
    'controlpanel',
    'newBooking',
    'addBooking',
    'REPORTS',
    'setting',
    '%D8%A7%D9%84%D9%88%D9%83%D9%8A%D9%84',
    'login',
    'manage-users',
    'messages',
    'newOrder',
    'landing',
    'Landing'
  ]);

  const { i18n } = useTranslation();

  const handelSetUser = (e) => {
    setUser(e);
  };

  /* change Language to i18n */
  const handelLang = (lang) => {
    setCurrentLang(lang);
  };

  const getStream = async () => {
    const allData = await getData();
    const res = Promise.resolve(allData);
    return res;
  };

  const [header, setHeader] = React.useState(true);
  const handelHeader = (e) => {
    setHeader(false);
  };

  React.useEffect(async () => {
    await app.auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        let userName = user.email;
        await checkPrivilage(userName).then(async (e) => {
          let userData = e.data;
          setUserData(userData);
          let tempPrivilage = {
            isAdmin: userData.isAdmin,
            isOwner: userData.isOwner,
            isAlwaqil: userData.isAlwaqil,
            isMiniAdmin: userData.isMiniAdmin,
            isSaler: userData.isSaler,
            isWorker: userData.isWorker
          };
          setPrivilege(tempPrivilage);
          setRefresh(refresh + 1);
        });
      }
    });

    setCurrentLang(i18n.language);

    const allData = getStream();
    allData.then((e) => {
      setData(e);
    });
  }, []);

  const lang = currentLang === 'ar' ? 'ar' : 'en';
  const locationInPathName = paths.includes(
    window.location.pathname.split('/')[1]
  );

  const handleTest = (e) => {
    setToggleDrawer(() => e);
  };

  return (
    <div
      className={`main-div ${lang} ${
        locationInPathName !== -1 ? 'systemNav' : 'webNav'
      }`}
    >
      <input type="hidden" value={refresh} />
      <AuthProvider>
        <Router privilege={privilege}>
          <div className="App">
            {header ? (
              <>
                {paths.includes(window.location.pathname.split('/')[1]) ? (
                  <>
                    <Header
                      test={(e) => {
                        handleTest(e);
                      }}
                      color="transparent"
                      brand=""
                      leftLinks={
                        <div
                          className="logoDIV"
                          style={{
                            display: 'flex',
                            justifyContent: `${
                              window.innerWidth < 1000 ? 'center' : 'flex-start'
                            }`
                          }}
                        >
                          <img
                            src={logo}
                            width={90}
                            onClick={() => {
                              // window.location.href = '/home';
                              window.open('/home', '_blank');
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      }
                      rightLinks={
                        <HeaderLinks2
                          toggle={() => {
                            toggleDrawer();
                          }}
                          handelLang={handelLang}
                          privilege={privilege}
                        />
                      }
                      fixed
                      changeColorOnScroll={{
                        height: 400,
                        color: 'dark'
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Header
                      test={(e) => {
                        handleTest(e);
                      }}
                      color="transparent"
                      brand=""
                      leftLinks={
                        <div
                          className="logoDIV"
                          style={{
                            display: 'flex',
                            justifyContent: `${
                              window.innerWidth < 1000 ? 'center' : 'flex-start'
                            }`
                          }}
                        >
                          <img
                            src={logo}
                            width={90}
                            onClick={() => {
                              window.location.href = '/home';
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      }
                      rightLinks={
                        <HeaderLinks
                          toggle={() => {
                            toggleDrawer();
                          }}
                          handelLang={handelLang}
                          privilege={privilege}
                        />
                      }
                      fixed
                      changeColorOnScroll={{
                        height: 400,
                        color: 'dark'
                      }}
                    />
                  </>
                )}
              </>
            ) : null}
          </div>

          <div className="component bgImage">
            <Route
              exact
              path="/"
              render={(props) => (
                <Home component={Home} currentLang={currentLang} />
              )}
            />
            <Route
              exact
              path="/home"
              render={(props) => (
                <Home component={Home} currentLang={currentLang} />
              )}
            />

            <Route
              exact
              path="/Projects"
              render={(props) => (
                <Projects
                  component={Projects}
                  projects={data}
                  currentLang={currentLang}
                />
              )}
            />

            <Route
              exact
              path="/Services"
              render={(props) => (
                <Services component={Services} projects={data} />
              )}
            />

            <Route
              exact
              path="/Projects/:id"
              render={(props) => (
                <EachProject
                  projects={data}
                  currentLang={currentLang}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/Projects/:id1/:id2"
              render={(props) => (
                <Gallery project={data} currentLang={currentLang} {...props} />
              )}
            />

            <PrivateRoute
              exact
              path="/Messages"
              user={user}
              redirectTo="/login"
              component={Messages}
            />

            <Route
              exact
              path="/login"
              render={(props) => (
                <Login handelSetUser={handelSetUser} userData={userData} />
              )}
            />

            <Route
              exact
              path="/barcodes"
              render={(props) => (
                <QRCode project={data} currentLang={currentLang} />
              )}
            />

            {
              // dashborad side
            }

            <PrivateRoute
              exact
              path="/ControlPanel"
              redirectTo="/login"
              projects={data}
              currentLang={currentLang}
              component={dashboardHome}
            />

            <PrivateRoute
              exact
              path="/ControlPanel/:id"
              redirectTo="/login"
              projects={data}
              currentLang={currentLang}
              component={SingleProject}
            />

            <PrivateRoute
              exact
              path="/booking"
              redirectTo="/login"
              projects={data}
              currentLang={currentLang}
              component={Homepage}
            />

            <PrivateRoute
              exact
              path="/mybooking"
              userData={userData}
              projects={data}
              redirectTo="/login"
              currentLang={currentLang}
              component={Mybooking}
            />

            <PrivateRoute
              exact
              path="/newBooking"
              redirectTo="/login"
              projects={data}
              currentLang={currentLang}
              component={newBooking}
            />
            <PrivateRoute
              exact
              path="/addBooking/:name"
              redirectTo="/login"
              projects={data}
              currentLang={currentLang}
              component={AddBooking}
            />

            <PrivateRoute
              exact
              path="/الوكيل"
              redirectTo="/login"
              currentLang={currentLang}
              projects={data}
              component={AlwaqilHomePage}
            />

            <PrivateRoute
              exact
              path="/REPORTS"
              redirectTo="/login"
              currentLang={currentLang}
              handelHeader={handelHeader}
              projects={data}
              component={HomeReports}
            />

            <PrivateRoute
              exact
              path="/manage-users"
              redirectTo="/login"
              currentLang={currentLang}
              component={ManageUsersHome}
            />

            <PrivateRoute
              exact
              path="/setting"
              redirectTo="/login"
              currentLang={currentLang}
              component={SettingHome}
            />

            <PrivateRoute
              exact
              path="/newOrder"
              redirectTo="/login"
              currentLang={currentLang}
              component={OrderHome}
            />

            <PrivateRoute
              exact
              path="/contract/:name"
              redirectTo="/login"
              currentLang={currentLang}
              project={data}
              handelHeader={handelHeader}
              component={ContractHome}
            />

            <PrivateRoute
              exact
              path="/attendance"
              redirectTo="/login"
              currentLang={currentLang}
              project={data}
              userData={userData}
              handelHeader={handelHeader}
              component={HomeAttendance}
            />

            <PrivateRoute
              exact
              path="/landing"
              redirectTo="/login"
              currentLang={currentLang}
              component={LandingHome}
            />

            <PrivateRoute
              exact
              path="/Test"
              redirectTo="/login"
              currentLang={currentLang}
              component={Test}
            />

            {/* <Redirect from="*" to="/" /> */}
          </div>
        </Router>
      </AuthProvider>
    </div>
  );
}
